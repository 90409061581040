import TScale from "../../../api/time/TScale";
import moment from "moment";

interface ITimeScaleCalcResult {
  hasError: boolean;
  scale: TScale;
}
/*
  this function receives to date time strings format:
  "2021-08-05T10:00:00Z"
  the strings are parsed and a specific scale is returned depending on the size of the time diff.

  The time algorithm aims for never showing more than 12 items.
  E.g. if the time diff is greater than 12 HOURS, scale is DAY,
  if time diff is greater than 12 days, scale is WEEK and so on.
 */
export default function getTimeScaleByStartAndEnd(
  //only diff is calculated, no timezone needed
  start: string,
  end: string,
  maxHoursAsDays = 1,
  maxDaysAsMonths = 1,
  maxWeeksAsMonths = 3,
  maxMonthsAsYears = 1,
): ITimeScaleCalcResult {
  let scale: TScale = "HOURS";
  const startMoment = moment(start);
  const endMoment = moment(end);
  const diff = endMoment.diff(startMoment);
  if (diff <= 0) {
    return {
      hasError: true,
      scale: "HOURS",
    };
  }
  if (moment.duration(diff).asDays() <= maxHoursAsDays) {
    scale = "HOURS";
  } else if (moment.duration(diff).asMonths() <= maxDaysAsMonths) {
    scale = "DAYS";
  } else if (moment.duration(diff).asMonths() <= maxWeeksAsMonths) {
    scale = "WEEKS";
  } else if (moment.duration(diff).asYears() <= maxMonthsAsYears) {
    scale = "MONTHS";
  } else {
    scale = "YEARS";
  }
  return {
    scale,
    hasError: false,
  };
}
