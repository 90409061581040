/*
 *
 *   Loader component which is used for displaying a loading status.
 *
 */

import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import ReactDOM from "react-dom/client";
import useStyles from "./styles";
import { logErrorToSentry } from "../../useSentry";

//------------------------------------------------------------------------------------------------------------
// Interface.
interface LoaderContainer extends HTMLDivElement {
  _root?: ReactDOM.Root;
}

//------------------------------------------------------------------------------------------------------------
// Constants
const LOADER_CONTAINER_ID = "service-worker-loader-container";
//------------------------------------------------------------------------------------------------------------
// Component.
function Loader(): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div className={classes.aligner}>
      <div className={classes.aligneritem}>
        <ScaleLoader />
      </div>
    </div>
  );
}

export const showLoader = (): LoaderContainer | undefined => {
  // Remove any existing loader first
  const existingLoader = document.getElementById(LOADER_CONTAINER_ID);
  if (existingLoader) {
    removeLoader(existingLoader as LoaderContainer);
  }

  try {
    const container = document.createElement("div") as LoaderContainer;
    container.id = LOADER_CONTAINER_ID;

    const { classes } = useStyles();
    container.className = classes.container;

    document.body.appendChild(container);

    container._root = ReactDOM.createRoot(container);
    container._root.render(<Loader />);

    return container;
  } catch (error) {
    logErrorToSentry(new Error("Error showing loader"), {
      level: "error",
      extra: {
        errorMessage: error,
      },
    });
    return undefined;
  }
};

export const removeLoader = (container: LoaderContainer): void => {
  try {
    if (container && document.body.contains(container)) {
      if (container._root) {
        container._root.unmount();
      }
      document.body.removeChild(container);
    }
  } catch (error) {
    logErrorToSentry(new Error("Error removing loader"), {
      level: "error",
      extra: {
        errorMessage: error,
      },
    });
    // Remove the container even if there is an error
    if (container && document.body.contains(container)) {
      document.body.removeChild(container);
    }
  }
};
export default Loader;
