import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  aligner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  aligneritem: {
    maxWidth: "50%",
  },
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.8)",
    zIndex: 9999,
  },
});

export default useStyles;
