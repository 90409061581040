/*
 *
 *   Energy Consumption view for machine dashboard.
 *
 */

import React, { useMemo } from "react";
import GridPage from "../../../../../shared/components/gridPage";
import getLayoutInfo, {
  BAR_CHART_LAYOUT_KEY,
  PIE_AVG_LAYOUT_KEY,
  PIE_TOTAL_LAYOUT_KEY,
  TResizeConfig,
} from "./layoutConfig";
import MachineStateBarChartStacked from "../../../../../components/charts/machineStateBarChartStacked";
import { useSelector } from "react-redux";
import { selectMachine } from "../../../../../redux/reducers/machineSelectReducer";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getSelectedMachineDowntimeList } from "../../../../../redux/reducers/downtimeReducer";
import { selectTimeSelection } from "../../../../../redux/reducers/timeSelectReducer";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import KPIValueDisplayCard from "../../../../../components/kpiValueDisplayCard";
import useValueIncreaseQuery from "../../../../../api/valueIncrease/useValueIncrease";
import { getTimezone } from "../../../../../helper/time/timezone";

export default function EnergyView(): React.ReactElement {
  const { t, i18n } = useTranslation();
  const timezone = getTimezone();
  const timeSelection = useSelector(selectTimeSelection);
  const selectedMachine = useSelector(selectMachine);

  const { valueIncreaseData, isLoading, hasError, isEmpty } =
    useValueIncreaseQuery(
      "counter_energy",
      selectedMachine,
      timeSelection.currentFilter,
      timezone,
      t,
      i18n.language,
    );
  const graphsData = useMemo(() => {
    if (valueIncreaseData != null) {
      return {
        barChartData: {
          chartData: valueIncreaseData.valueIncreaseData.data,
          parts: valueIncreaseData.valueIncreaseData.parts,
          YAxisLabel: valueIncreaseData.valueIncreaseData.yAxisLabel,
          isEmpty: isEmpty,
          isLoading: isLoading,
          hasError: hasError,
        },
        totalValueIncreaseData: {
          valueData: valueIncreaseData.totalValueIncreaseData,
          isEmpty: isEmpty,
          isLoading: isLoading,
          hasError: hasError,
        },
        avgValueIncreaseData: {
          valueData: valueIncreaseData.avgValueIncreaseData,
          isEmpty: isEmpty,
          isLoading: isLoading,
          hasError: hasError,
        },
      };
    }
    return undefined;
  }, [valueIncreaseData, isEmpty, isLoading, hasError]);
  const layoutConf = useMemo(() => getLayoutInfo({} as TResizeConfig), []);

  return (
    <GridPage layouts={layoutConf} containerPadding={[10, 10]}>
      <div key={PIE_TOTAL_LAYOUT_KEY}>
        <KPIValueDisplayCard
          defaultData={graphsData?.totalValueIncreaseData}
          icon={<ShowChartIcon />}
          label={t("common.total_energy_consumption.label")}
          tooltipLabel={t("common.total_energy_consumption.label")}
          tooltipSublabel={t("common.total_energy_consumption.sublabel")}
          tooltipDescription={t("common.total_energy_consumption.description")}
          tooltipFooter={t("common.total_energy_consumption.footer")}
          timeSelection={timeSelection}
          isUnitHidden={false}
          shouldReferenceBeHidden={true}
        />
      </div>
      <div key={PIE_AVG_LAYOUT_KEY}>
        <KPIValueDisplayCard
          defaultData={graphsData?.avgValueIncreaseData}
          icon={<AddCircleOutlineIcon />}
          label={t("common.average_energy_consumption.label")}
          tooltipLabel={t("common.average_energy_consumption.label")}
          tooltipSublabel={t("common.average_energy_consumption.sublabel")}
          tooltipDescription={t(
            "common.average_energy_consumption.description",
          )}
          tooltipFooter={t("common.average_energy_consumption.footer")}
          timeSelection={timeSelection}
          isUnitHidden={false}
          shouldReferenceBeHidden={true}
          scale={graphsData?.avgValueIncreaseData.valueData?.scale}
        />
      </div>
      <div key={BAR_CHART_LAYOUT_KEY}>
        <MachineStateBarChartStacked defaultData={graphsData?.barChartData} />
      </div>
    </GridPage>
  );
}
