import TScale from "../../../api/time/TScale";

export type TMode = "date" | "datetime" | "time" | "fulldatetime";

function getDateTimeFormat(
  lang: string | undefined,
  dateTimeMode: TMode,
): string {
  const isShowingDate = ["date", "datetime", "fulldatetime"].includes(
    dateTimeMode,
  );
  const isShowingTime = ["time", "datetime", "fulldatetime"].includes(
    dateTimeMode,
  );
  const isFullTime = dateTimeMode === "fulldatetime";
  const datePart = isShowingDate
    ? "dd DD.MM.yyyy" + (isShowingTime ? " " : "")
    : "";
  const timePart = isShowingTime ? (isFullTime ? "HH:mm:ss" : "HH:mm") : "";

  return datePart + timePart;
}

export default function getFormatDependingOnLang(
  lang: string | undefined,
  dateTimeMode: TMode,
  scaleParam?: TScale,
): string {
  const scale = scaleParam != null ? scaleParam : "DAYS";
  switch (scale) {
    case "WEEKS":
      // use iso WW instead of non iso ww
      return "WW";
    case "MONTHS":
      return "MMMM YY";
    case "YEARS":
      return "YYYY";
    default:
      return getDateTimeFormat(lang, dateTimeMode);
  }
}
