import React from "react";
import Skeleton from "@mui/material/Skeleton";
import useStyles from "./styles";

interface IProps {
  showYAxis?: boolean;
}
// Skeleton component for BarStackChart
export default function BarStackChartSkeleton({
  showYAxis = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "row" }}>
      {showYAxis && (
        <div className={classes.yAxisContainer}>
          <Skeleton
            variant="text"
            width={70}
            height={20}
            style={{
              alignSelf: "center",
              marginLeft: 10,
              marginRight: 0,
            }}
          />{" "}
          <Skeleton
            variant="text"
            width={70}
            height={20}
            style={{
              alignSelf: "center",
              marginLeft: 10,
              marginRight: 0,
            }}
          />{" "}
          <Skeleton
            variant="text"
            width={70}
            height={20}
            style={{
              alignSelf: "center",
              marginLeft: 10,
              marginRight: 0,
            }}
          />{" "}
          <Skeleton
            variant="text"
            width={70}
            height={20}
            style={{
              alignSelf: "center",
              marginLeft: 10,
              marginRight: 0,
            }}
          />{" "}
        </div>
      )}
      <div style={{ flex: 1 }}>
        <div className={classes.rowContainer}>
          <Skeleton variant="rectangular" width={`18%`} height={"70%"} />
          <Skeleton variant="rectangular" width={`18%`} height={"30%"} />
          <Skeleton variant="rectangular" width={`18%`} height={"90%"} />
          <Skeleton variant="rectangular" width={`18%`} height={"90%"} />
        </div>
        <div className={classes.lineContainer} /> {/* X-axis */}
        <div className={classes.axisContainer}>
          <Skeleton variant="text" width={"15%"} height={20} />
          <Skeleton variant="text" width={"15%"} height={20} />
          <Skeleton variant="text" width={"15%"} height={20} />
          <Skeleton variant="text" width={"15%"} height={20} />
        </div>
      </div>
    </div>
  );
}
