import { makeStyles } from "tss-react/mui";
import colors from "../../../../../../shared/theme/colors";

const useStyles = makeStyles()(() => ({
  container: {
    minHeight: 306,
    paddingTop: 48,
    paddingBottom: 48,
    width: "100%",
  },
  buttons: {
    display: "flex",
    marginTop: 60,
    marginBottom: 50,
    alignContent: "space-between",
    justifyContent: "space-evenly",
  },
  question: {
    marginTop: 50,
    maxWidth: 400,
  },
  inputs: {
    display: "flex",
    gap: "10px",
    width: "100%",
  },
  textComponent: {
    color: colors.blue_base,
    "& .MuiInputBase-root": {
      backgroundColor: "rgba(0, 0, 0, 0.06)",
      color: colors.blue_base,
    },
  },
  checkbox: {
    margin: "20px 0",
  },
  dateTimeGrid: {
    height: "100%",
    backgroundColor: `rgba(${0}, ${0}, ${0}, ${0.06})`,
    "&.Mui-disabled": {
      backgroundColor: `rgba(${0}, ${0}, ${0}, ${0.12})`,
    },
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  dateTimeBox: {
    height: 56,
    display: "block",
    width: "100%",
    flexBasis: "100%",
  },
  dateTimeInput: {
    height: "100%",
    margin: 0,
    display: "flex",
    alignItems: "center",
    "& .MuiInputLabel-root": {
      margin: "0 10px",
    },
    "& .MuiInputBase-input": {
      margin: "0 10px",
    },
  },
  dateTimeInputRoot: {
    marginBottom: -11,
    "& .MuiInputBase-input": {
      fontSize: 16,
    },
  },
  valueInput: {
    flex: "1 1 auto",
    width: "auto",
    // width: "100%",
  },
}));

export default useStyles;
