import React, { useMemo } from "react";
// Custom Imports.
import { useTranslation } from "react-i18next";
import OEEView from "./views/oeeView";
import StacklightView from "./views/stacklightView";
import TabsWithMachineTimePeriodSelection from "../../../shared/components/tabsWithMachineTimePeriodSelection";
import DowntimeReasonsView from "./views/downtimeReasonsView";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LastWeeksPerformanceView from "./views/lastWeeksPerformanceView";
import { useFlag } from "@unleash/proxy-client-react";
import EnergyView from "./views/energyView";
import { ITabConfig } from "../../../shared/components/tabsWithMachineTimePeriodSelection/helper";

// Component.
export default function Machine(): React.ReactElement {
  const knownPaths = [
    "/oee",
    "/stacklight",
    "/downtimes" /*, "/lastperformance"*/,
    "/energy",
  ];

  const { t } = useTranslation();

  const iskpiViewEnabled = true;
  const isstacklightViewEnabled = useFlag(
    "killswitch_dashboards_machine_stacklightView",
  );
  const isdowntimesViewEnabled = useFlag(
    "killswitch_dashboards_machine_downtimesView",
  );
  const isEnergyViewEnabled = useFlag("killswitch_dashboards_machine_energy");

  // the commented out code should be used when the backend is ready to provide the data for the last week's performance
  const tabConfig = useMemo(() => {
    const tabs: ITabConfig[] = [];

    tabs.push({
      label: t("common.kpi_long"),
      path: knownPaths[0],
      hidden: !iskpiViewEnabled,
    });
    tabs.push({
      label: t("common.stacklightView.label"),
      path: knownPaths[1],
      hidden: !isstacklightViewEnabled,
    });
    tabs.push({
      label: t("common.downtimesView"),
      path: knownPaths[2],
      hidden: !isdowntimesViewEnabled,
    });
    tabs.push({
      label: t("common.energyView"),
      path: knownPaths[3],
      hidden: !isEnergyViewEnabled,
    });

    // if (useFlag("killswitch_dashboards_machine_lastWeeksPerformance")) {
    //   tabs.push({
    //     label: t("common.lastWeeksPerformance"),
    //     path: "/lastperformance",
    //   });
    //   retValue.push(<LastWeeksPerformanceView key="last-weeks-performance-view" />);
    // }

    return tabs;
  }, [
    iskpiViewEnabled,
    isstacklightViewEnabled,
    isdowntimesViewEnabled,
    isEnergyViewEnabled,
    knownPaths,
    t,
  ]);

  return (
    <TabsWithMachineTimePeriodSelection
      basePath={"/dashboards/machine"}
      tabConfig={tabConfig}
      isTimePeriodSelectionShown={true}
      tabsWithRefererencePeriod={[tabConfig[0]?.path ?? ""]}
      tabsWithDisabledShift={isEnergyViewEnabled ? [tabConfig[3]?.path] : []}
      initShift={
        isEnergyViewEnabled && tabConfig[3]?.path ? "day_24h7" : undefined
      }
    >
      <OEEView key="oee-view" />
      <StacklightView key="stacklight-view" />
      <DowntimeReasonsView key="downtime-reasons-view" />
      <EnergyView key="energy-view" />
      {/* <LastWeeksPerformanceView /> */}
    </TabsWithMachineTimePeriodSelection>
  );
}
