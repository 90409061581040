import gql from "graphql-tag";

export default gql`
  query energyConsumptions(
    $intervalEnd: DateTime!
    $intervalStart: DateTime!
    $machineTypeId: Int!
    $variableListName: String!
    $timezone: String!
  ) {
    energyConsumptions(
      intervalEnd: $intervalEnd
      intervalStart: $intervalStart
      machineTypeId: $machineTypeId
      variableListName: $variableListName
      timezone: $timezone
    ) {
      data {
        tsStart
        tsEnd
        valueIncrease
      }
      unit {
        description
        displayName
        conversionFactor
      }
    }
  }
`;
