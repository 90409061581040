import { BarStackChartCard } from "../index";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIndicator } from "../../../redux/reducers/indicatorReducer";
import { useTranslation } from "react-i18next";
import transformDataFromServer from "./api/transformDataFromServer";
import useCondensedStateListQuery from "./api/useCondensedStateListQuery";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import { selectTimeSelection } from "../../../redux/reducers/timeSelectReducer";
import IBarChartDataElement from "../../../shared/components/charts/barStackChartCard/api/IBarChartDataElement";
import { IBarChartPart } from "../../../shared/components/charts/barStackChartCard";
import ServerDataStatusWrapper from "../../../shared/components/serverDataStatusWrapper";
import GeorgStorageContext from "../../../context/GeorgStorageContext";

interface defaultChartData {
  chartData: IBarChartDataElement[];
  parts: IBarChartPart[];
  YAxisLabel?: string;
  isEmpty: boolean;
  isLoading: boolean;
  hasError: boolean;
}
interface IProps {
  indicatorIds?: string[];
  defaultData?: defaultChartData;
}

function MachineStateBarChartStacked({
  indicatorIds = [],
  defaultData,
}: IProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const selectedMachine = useSelector(selectMachine);
  const timeSelection = useSelector(selectTimeSelection);
  const [data, setData] = useState<IBarChartDataElement[]>([]);
  const [parts, setParts] = useState<IBarChartPart[]>([]);
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [hasDataError, setHasDataError] = useState<boolean>(false);
  const [showYAxis, setShowYAxis] = useState<boolean>(false);
  const [YAxisLabel, setYAxisLabel] = useState<string>();
  const { timezone } = useContext(GeorgStorageContext);
  const { condensedStatelist, isEmpty, isLoading, hasError } =
    useCondensedStateListQuery(
      indicatorIds,
      timeSelection.currentFilter,
      timezone,
      selectedMachine,
    );
  useEffect(() => {
    if (indicatorIds.length === 0 && defaultData) {
      setData(defaultData.chartData);
      setParts(defaultData.parts);
      setIsDataEmpty(defaultData.isEmpty);
      setIsDataLoading(defaultData.isLoading);
      setHasDataError(defaultData.hasError);
      setShowYAxis(true);
      setYAxisLabel(defaultData.YAxisLabel);
    } else {
      setIsDataEmpty(isEmpty);
      setIsDataLoading(isLoading);
      setHasDataError(hasError);
      setShowYAxis(false);
      setYAxisLabel(undefined);
    }
  }, [defaultData, indicatorIds, isEmpty, isLoading, hasError]);
  const allIndicators = useSelector(selectIndicator);
  const locale = i18n.language;
  useEffect(() => {
    if (condensedStatelist != null && !hasError && !isLoading && !isEmpty) {
      const { data: newData, parts: newParts } = transformDataFromServer(
        locale,
        timezone,
        t,
        condensedStatelist,
        allIndicators,
        indicatorIds.map((idStr) => parseInt(idStr, 10)),
      );
      setData(newData);
      setParts(newParts);
    }
  }, [
    indicatorIds,
    locale,
    condensedStatelist,
    allIndicators,
    isEmpty,
    isLoading,
    hasError,
  ]);

  return (
    <ServerDataStatusWrapper
      isEmpty={isDataEmpty && !isDataLoading}
      hasError={hasDataError}
    >
      <BarStackChartCard
        label={t("dashboard.stacklight.machineStates.barChartStacked.title")}
        barChartParts={parts}
        chartElements={data}
        isLoading={isDataLoading}
        isEmpty={isDataEmpty}
        hasError={hasDataError}
        timezone={timezone}
        locale={locale}
        machineId={selectedMachine}
        showYAxis={showYAxis}
        YAxisLabel={YAxisLabel}
      />
    </ServerDataStatusWrapper>
  );
}

export default React.memo(MachineStateBarChartStacked);
