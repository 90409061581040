import {
  useNavigate,
  useParams,
  useLocation,
  useResolvedPath,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMachine,
  setMachine,
} from "../../../redux/reducers/machineSelectReducer";
import { selectMachineList } from "../../../redux/reducers/machineListReducer";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { IInput, ITabs } from "../index";
import useDefaultPage from "../../../services/routing/useDefaultPage";
import {
  Box,
  Container,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Layouts } from "react-grid-layout";
import { NoMachineView } from "../../../components";
import useStyles from "./styles";
import { useTheme } from "@mui/material/styles";
import TimeSelectionCurrentReference from "../timeSelectionCurrentReference";
import { getTimezone } from "../../../helper/time/timezone";
import GridPage from "../gridPage";
import {
  getLayoutInfo,
  getSingleComponentLayoutInfo,
  getSmallLayoutInfo,
  MACHINE_SELECTION_LAYOUT_KEY,
  TIME_SELECTION_LAYOUT_KEY,
  TResizeConfig,
} from "./layoutConfig";
import {
  IProps,
  ISelectOption,
  getIsTabExistInList,
  getIsMachineMatchingFunction,
  getIsShowingReference,
  getPathToSwitch,
} from "./helper";
import { IMachine } from "../../../api/machine/IMachine";
import MachineOption from "./components/machineOption";
import { TFunction } from "i18next";

export function createMachineOptionFromMachine(
  machine: IMachine,
): ISelectOption {
  const label =
    `${machine.deviceLabel}${machine.deviceLabelExtension}
     ${machine.deviceSublabel} ` + `(${machine.id})`;
  const sublabel = machine.deviceClass;

  return {
    value: machine.id,
    label: <MachineOption label={label} sublabel={sublabel} />,
  };
}

export function createSelectOptionsForMachines(
  t: TFunction,
  machines: IMachine[],
  preSelectedMachineId: string,
): ISelectOption[] {
  const preSelectedMachine: IMachine | undefined = machines.find(
    getIsMachineMatchingFunction(preSelectedMachineId),
  );
  const label = t("headers.dashboards_machine.headline");
  const sublabel = t("headers.dashboards_machine.tagline");
  const defaultSelection: ISelectOption = {
    value: "",
    label: <MachineOption label={label} sublabel={sublabel} />,
  };
  // Create Select Options.
  const selectOptionsFromMachines = machines.map(
    createMachineOptionFromMachine,
  );
  // when no machine is selected, the default selection is added to the list
  if (!preSelectedMachine) {
    selectOptionsFromMachines.unshift(defaultSelection);
  }

  return selectOptionsFromMachines;
}

const isNumeric = (str: string): boolean => {
  return !isNaN(Number(str)) && str.trim() !== "";
};

const checkIfKnownPath = (
  tabConfig: IProps["tabConfig"],
  fullpath: string,
  relativePath: string,
  basePath: string,
  selectedMachine: string,
): boolean => {
  const knownPaths = tabConfig.map((tab) => tab.path);
  let currentChildPath = "";
  if (fullpath === relativePath) {
    currentChildPath = fullpath.replace(basePath, "");
  } else {
    currentChildPath = fullpath.replace(relativePath, "");
  }
  if (isNumeric(selectedMachine)) {
    currentChildPath.replace("/" + selectedMachine, "");
  }
  return knownPaths.includes(currentChildPath);
};

/*
    This component wraps the machine selection with tabs and maps the selected
    machine into it's child components.
 */
export default function TabsWithMachineTimePeriodSelection({
  basePath,
  tabConfig,
  children,
  isTimePeriodSelectionShown = false,
  tabsWithRefererencePeriod,
  tabsWithoutTimePeriod,
  tabsWithDisabledShift = [],
  initShift = "",
  hideSingleTab = false,
}: IProps): React.ReactElement {
  const params = useParams() as {
    machineId: string;
  };
  const selectedMachineRef = useRef<string>("");
  const timezone = getTimezone();
  const machineIdFromUrl = params.machineId || "";
  const selectedMachineInReduxStore: string = useSelector(selectMachine);
  const machines = useSelector(selectMachineList);

  const location = useLocation();
  const resolvedPath = useResolvedPath(".").pathname;

  const useCheckIfKnownPath = useMemo(
    () =>
      checkIfKnownPath(
        tabConfig,
        location.pathname,
        resolvedPath,
        basePath,
        selectedMachineInReduxStore,
      ),
    [location, resolvedPath, basePath, selectedMachineInReduxStore, tabConfig],
  );

  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (!useCheckIfKnownPath) {
      // return <NotFound />;
      navigate(basePath + tabConfig[0].path);
    }
  }, [useCheckIfKnownPath]);

  selectedMachineRef.current = selectedMachineInReduxStore;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const selectOptions = useMemo(
    () => createSelectOptionsForMachines(t, machines, machineIdFromUrl),
    [machines, machineIdFromUrl],
  );
  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newMachineId = event.target.value;
      const path = getPathToSwitch(basePath, newMachineId);
      navigate(path);
      dispatch(setMachine(newMachineId));
    },
    [getPathToSwitch, location, basePath, navigate],
  );

  const isMachineMatchingFunction =
    getIsMachineMatchingFunction(machineIdFromUrl);

  const isMachineMatching = useMemo(
    () => machines.some(isMachineMatchingFunction),
    [machines, isMachineMatchingFunction],
  );

  const [parentBreakPoint, setParentBreakPoint] = useState("");
  const [isShowingReference, setIsShowingReference] = useState(false);
  const [isTabWithoutTimePeriod, setIsTabWithoutTimePeriod] = useState(
    !isTimePeriodSelectionShown,
  );
  const [isShiftDisabled, setIsShiftDisabled] = useState(false);
  const [resizeConf, setResizeconf] = useState<TResizeConfig>(
    {} as TResizeConfig,
  );
  const layoutConf = useMemo(() => {
    return !isTabWithoutTimePeriod && selectedMachineRef.current !== ""
      ? isShowingReference
        ? getLayoutInfo(resizeConf)
        : getSmallLayoutInfo(resizeConf)
      : getSingleComponentLayoutInfo(resizeConf);
  }, [
    resizeConf,
    isTimePeriodSelectionShown,
    isShowingReference,
    getLayoutInfo,
    getSmallLayoutInfo,
    getSingleComponentLayoutInfo,
    parentBreakPoint,
    selectedMachineRef.current,
    isTabWithoutTimePeriod,
  ]);

  const [currentLayoutConf, setCurrentLayoutConf] = useState<Layouts>({});

  useEffect(() => {
    selectedMachineRef.current = selectedMachineInReduxStore;
  }, [selectedMachineInReduxStore]);

  useLayoutEffect(() => {
    setCurrentLayoutConf(layoutConf);
  }, [layoutConf]);

  useEffect(() => {
    // if we have no selected machine within redux state
    // but a valid machine id within URL, we set the machine id from URL
    if (!selectedMachineRef.current && isMachineMatching) {
      dispatch(setMachine(machineIdFromUrl));
    }
  }, [isMachineMatching]);

  const redirectTo =
    selectedMachineRef.current !== ""
      ? `${basePath}/${selectedMachineRef.current}${tabConfig[0].path}`
      : basePath;

  const onTabChange = useCallback(
    (newPath: string) => {
      setIsShowingReference(
        getIsShowingReference(
          newPath,
          selectedMachineRef.current,
          isTimePeriodSelectionShown,
          tabsWithRefererencePeriod,
        ),
      );
      setIsTabWithoutTimePeriod(
        !isTimePeriodSelectionShown ||
          getIsTabExistInList(newPath, tabsWithoutTimePeriod),
      );
      setIsShiftDisabled(getIsTabExistInList(newPath, tabsWithDisabledShift));
    },
    [
      isTimePeriodSelectionShown,
      tabsWithRefererencePeriod,
      selectedMachineRef.current,
    ],
  );
  useLayoutEffect(() => {
    onTabChange(location.pathname);
  }, [selectedMachineRef.current]);

  useDefaultPage(`${basePath}/${selectedMachineRef.current}`, redirectTo);

  const { breakpoints } = useTheme();
  const isWidthBelowMD = useMediaQuery(breakpoints.down("md"));

  const tabContent = useMemo(() => {
    const normalizedChildren = Array.isArray(children) ? children : [children];
    return selectedMachineRef.current === "" ? [] : [...normalizedChildren];
  }, [children, selectedMachineRef.current]);

  const stylesObj = useMemo(() => {
    return {
      menu: classes.menu,
      rootInputStyles: classes.rootInput,
      textInputStyles: classes.textInput,
      selectIcon: classes.selectIcon,
    };
  }, []);

  const onBreakpointChange = useCallback(
    (newBreakpoint: string) => {
      setParentBreakPoint(newBreakpoint);
    },
    [setParentBreakPoint, isShowingReference, selectedMachineRef.current],
  );

  const areTabsHidden = useMemo(() => {
    return hideSingleTab && tabConfig.length === 1;
  }, [hideSingleTab, tabConfig]);

  return (
    <Container disableGutters={isWidthBelowMD} className={classes.container}>
      <GridPage
        layouts={currentLayoutConf}
        gridBackgroundColor="transparent"
        containerPadding={[0, 0]}
        itemMargin={[40, 0]}
        onBreakpointChange={onBreakpointChange}
      >
        <div key={MACHINE_SELECTION_LAYOUT_KEY}>
          <Box>
            <Typography color="textPrimary" variant="body1">
              {t("common.machines")}
            </Typography>
            <Paper className={classes.inputContainer}>
              <IInput
                disableUnderline
                fullWidth={isWidthBelowMD}
                name="machine-select"
                onChange={handleSelect}
                select
                selectOptions={selectOptions}
                styles={stylesObj}
                value={selectedMachineRef.current}
              />
            </Paper>
          </Box>
        </div>
        {isTimePeriodSelectionShown &&
        !isTabWithoutTimePeriod &&
        selectedMachineRef.current ? (
          <div key={TIME_SELECTION_LAYOUT_KEY}>
            <TimeSelectionCurrentReference
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onNeedsMoreHeight={(offsetForHeight: number) => {
                setResizeconf({
                  ...resizeConf,
                  [TIME_SELECTION_LAYOUT_KEY]: offsetForHeight,
                });
              }}
              isShowingReference={isShowingReference}
              timezone={timezone}
              parentBreakPoint={parentBreakPoint}
              isShiftDisabled={isShiftDisabled}
              initShift={initShift}
            />
          </div>
        ) : (
          <div></div>
        )}
      </GridPage>
      <div style={{ marginTop: areTabsHidden ? 40 : 0 }}>
        {useCheckIfKnownPath && (
          <ITabs
            disableContentPadding
            disableTabs={selectedMachineRef.current === ""}
            disableRipple
            tabs={tabConfig}
            tabContent={tabContent}
            tabVariant="fullWidth"
            onTabChange={onTabChange}
            areTabsHidden={areTabsHidden}
          />
        )}
        {selectedMachineRef.current === "" ? <NoMachineView /> : null}
      </div>
    </Container>
  );
}
