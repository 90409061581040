import { makeStyles } from "tss-react/mui";
import { colors } from "../../theme";

const useStyles = makeStyles()({
  timePicker: {
    width: "100%",
    minWidth: 120,
  },
  container: {
    height: "100%",
    marginRight: 20,
    display: "flex",
    alignItems: "center",
  },
  dialog: {
    "& .MuiButtonBase": {
      color: "black",
    },
    "& .MuiDialog-paper": {
      width: 332,
      maxHeight: 710,
    },
  },
  rootTimePicker: {
    "& .MuiDialog-paper": {
      width: 500,
    },
    // Underline color on clicked Input Field.
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.blue_base,
    },
    // Underline Color before hover and focus.
    "& .MuiInput-underline": {
      borderBottomColor: colors.grey_base,
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: colors.grey_base,
    },
    // TextField label.
    "& .MuiInputBase-input": {
      color: colors.blue_base,
    },
  },
  defaultTimePickerRoot: {
    "& .MuiInputBase-input": {
      fontSize: 13,
    },
  },
  pendingRootTimePicker: {
    "& .MuiInputBase-input": {
      backgroundColor: colors.greyB56,
    },
  },
});

export default useStyles;
