import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { ITimeSlot } from "../../redux/reducers/timeSelectReducer";
import createValueIncreaseQuery from "./createValueIncreaseQuery";
import IValueIncreaseResponse from "./interface/IValueIncreaseResponse";
import { TFunction } from "i18next";
import { ValueIncreaseReducer } from "./valueIncreaseReducer";
import { IValueIncreaseFrontendData } from "./interface/IValueIncreaseFrontendData";

interface IValueIncreaseQueryState {
  valueIncreaseData: IValueIncreaseFrontendData | null;
  isLoading: boolean;
  hasError: boolean;
  isEmpty: boolean;
}

// This hook is used to fetch the indicator list with the given IndicatorListName and dispatch it to the redux store.
export default function useValueIncreaseQuery(
  variableListName: string,
  machineTypeId: string,
  timeSlot: ITimeSlot,
  timezone: string,
  tF: TFunction,
  locale: string,
): IValueIncreaseQueryState {
  const [isEmpty, setIsEmpty] = useState(true);
  const { startedAfter, endedBefore } = timeSlot;

  const { query, parameters } = createValueIncreaseQuery(
    parseInt(machineTypeId, 10),
    variableListName,
    startedAfter,
    endedBefore,
    timezone,
  );

  const [doQuery, { data: dataFromServer, loading: isLoading, error }] =
    useLazyQuery<IValueIncreaseResponse>(query, parameters);

  useEffect(() => {
    if (machineTypeId !== "" && variableListName !== "") {
      doQuery();
    }
  }, [machineTypeId, variableListName, timeSlot, doQuery]);

  const valueIncreaseData: IValueIncreaseFrontendData | null = useMemo(() => {
    const isEmptyData =
      dataFromServer === undefined ||
      dataFromServer.energyConsumptions?.data.length === 0;
    let newData: IValueIncreaseFrontendData = {
      valueIncreaseData: {
        data: [],
        parts: [],
      },
      totalValueIncreaseData: undefined,
      avgValueIncreaseData: undefined,
    };
    if (
      !error &&
      !isLoading &&
      dataFromServer != null &&
      dataFromServer.energyConsumptions != null &&
      dataFromServer.energyConsumptions.data != null &&
      dataFromServer.energyConsumptions.data.length > 0 &&
      dataFromServer.energyConsumptions.unit != null
    ) {
      newData = ValueIncreaseReducer(
        dataFromServer.energyConsumptions.data,
        dataFromServer.energyConsumptions.unit,
        variableListName,
        timezone,
        locale,
        tF,
      );
    }
    setIsEmpty(isEmptyData || newData.valueIncreaseData.data.length === 0);
    return newData;
  }, [dataFromServer, isLoading, error]);
  return { valueIncreaseData, isLoading, hasError: error != null, isEmpty };
}
