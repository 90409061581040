/*
 *
 *   Time selection for report-view, kpi-view and stacklightView-view.
 *
 */
import React, { useEffect, useState } from "react";
// Custom Imports.
import Dropdown from "../../../shared/components/dropDown";
import IShiftOption from "./IShiftOption";
import useStyles from "./styles";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface IProps {
  name?: string;
  onShiftChange?: (selected: string) => void;
  label: string;
  shiftOptions: IShiftOption[];
  initShift: string;
  isTextContainerNeeded?: boolean;
  isChangePending?: boolean;
  isDisabled?: boolean;
}

export default function ShiftSelection({
  name,
  label,
  onShiftChange,
  shiftOptions,
  initShift,
  isTextContainerNeeded = false,
  isChangePending = false,
  isDisabled = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const [value, setValue] = useState<string>(initShift);
  useEffect(() => {
    setValue(initShift);
  }, [initShift]);
  return (
    <Dropdown
      value={shiftOptions.length ? value : ""}
      label={label}
      onChange={(selected: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = selected.target.value;
        setValue(newValue);
        if (onShiftChange) {
          onShiftChange(newValue);
        }
      }}
      name={name || ""}
      selectOptions={shiftOptions}
      styles={{
        inputContainer: classes.inputContainer,
        textContainer: isTextContainerNeeded ? classes.textContainer : "",
        boxContainer: classes.boxContainer,
        inputRoot: `${
          isChangePending ? classes.pendingInputRoot : classes.inputRoot
        }`,
        textInput: classes.textInput,
      }}
      isDisabled={isDisabled}
    />
  );
}
