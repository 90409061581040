import { DocumentNode } from "graphql";
import getEnergyConsumptionsQuery from "./getEnergyConsumptions";
import { ErrorPolicy } from "@apollo/client";

interface IValueIncreaseQueryConfig {
  query: DocumentNode;
  parameters: {
    variables: {
      intervalEnd: string;
      intervalStart: string;
      machineTypeId: number;
      variableListName: string;
      timezone: string;
    };
    errorPolicy?: ErrorPolicy;
  };
}

export default function createValueIncreaseQuery(
  machineTypeId: number,
  variableListName: string,
  intervalStart: string,
  intervalEnd: string,
  timezone: string,
): IValueIncreaseQueryConfig {
  return {
    query: getEnergyConsumptionsQuery,
    parameters: {
      variables: {
        intervalEnd,
        intervalStart,
        machineTypeId,
        variableListName,
        timezone,
      },
      errorPolicy: "all",
    },
  };
}
