import React, { useMemo } from "react";
import StateTooltip from "../../../../stateTooltip";
import secondsToDisplayFormat from "../../../../../../helper/time/formatting/secondsToDisplayFormat";
import displayFormatToString from "../../../../../../helper/time/formatting/displayFormatToString";
import { useTranslation } from "react-i18next";
import getIndicatorTranslationByStateName from "../../../../../../helper/indicator/getIndicatorTranslationByStateName";
import { TIndicatorName } from "../../../../../../config/states/STATE_COLOR_MAP";
import { ProcessValue } from "../../../../../../helper/valueRepresentation/customRoundedCurrentReference";
import { TFunction } from "i18next";

interface ITooltipPayload {
  name: string;
  value: string;
  color: string;
  unit?: string;
  payload: {
    name: string;
  };
}

export interface ITooltipProps {
  payload: ITooltipPayload[];
}

// Hint: We do not use useStyles hook within this component,
// because this component breaks hooks rules
interface IProps {
  tooltipData: ITooltipProps;
  isDateTime?: boolean;
}
function getValue(value: string): { value: string; unit: string } {
  const isValidNumber = (val: string) => !isNaN(Number(val));

  if (!isValidNumber(value)) {
    return { value, unit: "" };
  }

  const processedValue = ProcessValue(Number(value));

  return processedValue
    ? { value: processedValue.value.toString(), unit: processedValue.unit }
    : { value, unit: "" };
}

export function getTranslatedDisplayName(name: string, t: TFunction): string {
  const translatedName = getIndicatorTranslationByStateName(
    name as TIndicatorName,
    t,
  ).name;

  return translatedName || name;
}

const ChartTooltip = ({
  tooltipData,
  isDateTime = true,
}: IProps): React.ReactElement => {
  const { t } = useTranslation();

  // Return an empty fragment if there is no payload
  if (!tooltipData.payload) return <></>;

  const rowConf = useMemo(() => {
    const getLabelTextAndSuffix = (item: ITooltipPayload) => {
      if (isDateTime) {
        return {
          labelText: displayFormatToString(
            t,
            secondsToDisplayFormat(item.value as string),
          ),
          labelSuffix: "",
        };
      } else {
        const { value, unit } = getValue(item.value);
        return {
          labelText: value,
          labelSuffix: unit,
        };
      }
    };

    return tooltipData.payload.map((item: ITooltipPayload) => {
      const displayName = getTranslatedDisplayName(item.name, t);
      const { labelText, labelSuffix } = getLabelTextAndSuffix(item);

      return {
        color: item.color,
        label: `${displayName}: ${labelText} ${labelSuffix}${item.unit ?? ""}`,
      };
    });
  }, [tooltipData.payload, isDateTime, t]);

  // Get the xAxisLabel from the first item's payload name if available
  const xAxisLabel = tooltipData.payload[0]?.payload?.name || "";

  // Reverse row configuration for display
  const reversedRowConf = useMemo(() => rowConf.reverse(), [rowConf]);

  return <StateTooltip label={xAxisLabel} config={reversedRowConf} />;
};

export default ChartTooltip;
