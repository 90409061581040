import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SingleValueDisplay from "./singleValueDisplay";
import useStyles from "./styles";
import { colors } from "../../theme";
import { IAnomalyInfo } from "../../../components/charts/nestedKpiPieChartCard";
interface IProps {
  current?: number;
  target?: number;
  currentMetric?: string;
  referenceMetric?: string;
  currentPeriodLabel: string;
  referencePeriodLabel: string;
  currentShiftLabel: string;
  referenceShiftLabel: string;
  isLoading?: boolean;
  anomalyInfo: IAnomalyInfo;
  isReferenceHidden: boolean;
}

export default function ValueDisplay({
  current,
  target,
  currentMetric = "",
  referenceMetric = "",
  currentPeriodLabel,
  referencePeriodLabel,
  currentShiftLabel,
  referenceShiftLabel,
  isReferenceHidden,
  isLoading = false,
  anomalyInfo,
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  const mainContainerRef = useRef<HTMLDivElement>(null);
  const [forceWidth, setForceWidth] = useState<number>(0);
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  const anomalyData = useMemo(() => {
    const getWarning = (condition: boolean) =>
      condition
        ? { tooltipInfo: anomalyInfo.tooltipInfo, icon: anomalyInfo.icon }
        : { tooltipInfo: undefined, icon: undefined };

    return [getWarning(anomalyInfo.current), getWarning(anomalyInfo.reference)];
  }, [
    anomalyInfo.current,
    anomalyInfo.reference,
    anomalyInfo.tooltipInfo,
    anomalyInfo.icon,
  ]);

  const updateWidth = useCallback(
    (width: number) => setForceWidth((prev) => Math.max(prev, width)),
    [],
  );

  const checkOverflow = useCallback(() => {
    return (
      mainContainerRef.current !== null &&
      mainContainerRef.current.offsetWidth <
        mainContainerRef.current.scrollWidth
    );
  }, [mainContainerRef, mainContainerRef.current?.offsetWidth]);

  useEffect(() => {
    const handleResize = () => {
      setIsOverflow(checkOverflow());
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [checkOverflow, forceWidth]);

  const maxWidth = useMemo(() => {
    return mainContainerRef.current
      ? mainContainerRef.current.offsetWidth
      : "unset";
  }, [mainContainerRef.current?.offsetWidth]);

  const displayProps = (isTarget: boolean) => ({
    value: isTarget ? target : current,
    metric: isTarget ? referenceMetric : currentMetric,
    leftText: isTarget ? referencePeriodLabel : currentPeriodLabel,
    rightText: isTarget ? referenceShiftLabel : currentShiftLabel,
    style: isTarget ? { marginTop: "20px" } : {},
    updateWidth: updateWidth,
    forceWidth: forceWidth,
    isParentOverflow: isOverflow,
    maxWidth: maxWidth,
    isLoading: isLoading,
    lineColor: isTarget ? colors.greyB16 : colors.blue_base,
    anomalyInfo: anomalyData[isTarget ? 1 : 0],
  });

  return (
    <div ref={mainContainerRef} className={classes.mainContainer}>
      <SingleValueDisplay {...displayProps(false)} />
      {!isReferenceHidden && <SingleValueDisplay {...displayProps(true)} />}
    </div>
  );
}
