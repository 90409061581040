import TScale from "../../../api/time/TScale";

// https://stackoverflow.com/questions/16590500/calculate-date-from-week-number-in-javascript
function getDateOfISO8601Week(w: number, y: number): Date {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

export function getDateOfISO8601(
  year: number,
  month: number,
  week: number,
  day: number,
  scale: TScale,
): Date {
  switch (scale) {
    case "DAYS":
      return new Date(year, month, day);
    case "WEEKS":
      return getDateOfISO8601Week(week, year);
    case "MONTHS":
      return new Date(year, month, 1);
    case "YEARS":
      return new Date(year, 0, 1);
    default:
      throw new Error("Unsupported scale");
  }
}

export function convertStringTime(t: string): number {
  if (t === "null") {
    return 0;
  } else {
    return parseInt(t, 10);
  }
}
