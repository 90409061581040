import moment from "moment-timezone";
import TScale from "../../../api/time/TScale";

// This function is used to determine the scale of the time range to be displayed in the chart's X-Axis and Label.
export default function getScaleForStartEndTimestamps(
  start: Date,
  end: Date,
  timezone: string,
): TScale {
  const startMom = moment.tz(start, timezone);
  const endMom = moment.tz(end, timezone);
  const diff = endMom.diff(startMom);
  const duration = moment.duration(diff);
  const roundYears = Math.round(duration.asYears());
  const roundMonths = Math.round(duration.asMonths());
  const roundWeeks = Math.round(duration.weeks());
  const roundDays = Math.round(duration.days());

  if (roundYears >= 1) {
    return "YEARS";
  } else if (roundMonths === 1) {
    return "MONTHS";
  } else if (roundWeeks === 1) {
    return "WEEKS";
  } else if (roundDays === 1) {
    return "DAYS";
  } else {
    if (duration.weeks() >= 1) {
      return "MONTHS";
    } else if (duration.days() >= 1) {
      return "WEEKS";
    } else if (duration.hours() > 1) {
      return "DAYS";
    }
    return "HOURS";
  }
}
