import { DocumentNode } from "graphql";
import gql from "graphql-tag";

// Function to dynamically generate the GraphQL query
export default function aggregatedVariableQuery(
  valueName: string,
): DocumentNode {
  return gql`
    query aggregatedVariableQuery(
      $currentStart: DateTime!,
      $currentEnd: DateTime!,
      $referenceStart: DateTime!,
      $referenceEnd: DateTime!,
      $variableListIdentificationTypeId: ID!,
      $type: VariableDataTypeEnum!,
      $function: VariableAggregateFunctionEnum!
    ) {
      aggregatedVariableReferenceChartData(
        currentStart: $currentStart,
        currentEnd: $currentEnd,
        referenceStart: $referenceStart,
        referenceEnd: $referenceEnd,
        variableListIdentificationTypeId: $variableListIdentificationTypeId,
        type: $type,
        function: $function
      ) {
        current {
          ${valueName}
        }
        reference {
          ${valueName}
        }
        unit {
          description
          displayName
          conversionFactor
        }
      }
    }
  `;
}
