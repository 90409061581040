/*
 *
 *   Individual time selection for report-view, kpi-view and stacklightView-view.
 *
 */
import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
// Custom Imports.
import useStyles from "./styles";
import {
  MobileDateTimePicker,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { TMode } from "../../../helper/time/formatting/getFormatDependingOnLang";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.

interface IStyleObject {
  container?: string;
  rootContainer?: string;
}

interface SharedPickerProps {
  label?: string | null;
  disabled?: boolean;
  className?: string;
  value?: Date;
  format: string;
  onError?: (hasError: boolean) => void;
  onChange?: (date: Date | null) => void;
  onAccept?: (date: Date | null) => void;
  localeText: {
    timePickerToolbarTitle?: string;
    dateTimePickerToolbarTitle?: string;
    openPreviousView?: string;
    openNextView?: string;
    cancelButtonLabel?: string;
    previousMonth?: string;
    nextMonth?: string;
  };
  timezone?: string;
}

// this is a workaround for excluding ampm in a picker component that has no ampm prop
interface DateTimeAndTimePickerProps extends SharedPickerProps {
  ampm: boolean;
}
interface Props {
  label?: string | null;
  onTimeSelect: (d: Date) => void;
  value?: Date;
  locale?: string;
  onError?: (hasError: boolean) => void;
  mode?: TMode;
  disableUnderline?: boolean;
  isContentEditable?: boolean;
  isChangePending?: boolean;
  styles?: IStyleObject;
  timezone?: string;
  disabled?: boolean;
}

function getElementByMode(
  mode: TMode,
):
  | typeof MobileDateTimePicker
  | typeof MobileDatePicker
  | typeof MobileTimePicker {
  switch (mode) {
    case "datetime":
    case "fulldatetime":
      return MobileDateTimePicker;
    case "date":
      return MobileDatePicker;
    case "time":
      return MobileTimePicker;
  }
}
/*
Attention!
Do not get confused with src/helper/time/getFormatDependingOnLang
The DateTimeSelection is using a different time format standard!
 */
function getTimeFormatForSelection(mode: TMode, locale: string): string {
  if (mode === "time") {
    return "HH:mm";
  }
  if (locale === "de") {
    const date = "dd.MM.yyyy";
    if (mode === "datetime") {
      return `HH:mm ${date}`;
    } else {
      return date;
    }
  } else {
    const date = "yyyy/MM/dd";
    if (mode === "datetime") {
      return `HH:mm ${date}`;
    } else {
      return date;
    }
  }
}
//------------------------------------------------------------------------------------------------------------
// Component.
export default function DateTimeSelection({
  label,
  onTimeSelect,
  value,
  onError,
  mode = "datetime",
  styles = {
    container: "",
    rootContainer: "",
  },
  disableUnderline = false,
  isChangePending = false,
  timezone = "UTC",
  disabled = false,
}: Props): React.ReactElement {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const { classes } = useStyles();
  const format = useMemo(() => {
    return getTimeFormatForSelection(mode, locale);
  }, [locale, mode]);
  const DatePickerComponent = getElementByMode(mode);
  const [timeValue, setTimeValue] = useState<Date | undefined>(value);
  useEffect(() => {
    setTimeValue(value);
  }, [value]);
  const sharedProps: SharedPickerProps = {
    label: label ? label : t("shared.dateTimeSelection.defaultLabel"),
    localeText: {
      timePickerToolbarTitle:
        t("shared.dateTimeSelection.defaultLabel") + " (UTC)",
      dateTimePickerToolbarTitle: t(
        "shared.dateTimeSelection.defaultLabelDateTime",
      ),
      openPreviousView: t("shared.dateTimeSelection.openPreviousView"),
      openNextView: t("shared.dateTimeSelection.openNextView"),
      cancelButtonLabel: t("shared.dateTimeSelection.cancelBtn"),
      previousMonth: t("shared.dateTimeSelection.prevMonth"),
      nextMonth: t("shared.dateTimeSelection.nextMonth"),
    },
    className: classes.timePicker,
    value: timeValue,
    format: format,
    onError: () => {
      if (onError) {
        onError(true);
      }
    },
    onChange: (newValue) => {
      if (newValue) {
        setTimeValue(newValue);
      }
    },
    onAccept: (newValue) => {
      if (newValue) {
        onTimeSelect(new Date(newValue.getTime()));
      }
    },
    timezone: timezone,
    disabled: disabled,
  };
  const rootStyle = useMemo(
    () =>
      (styles.rootContainer?.length ?? 0) > 0
        ? styles.rootContainer
        : classes.defaultTimePickerRoot,
    [styles.rootContainer],
  );

  let pickerProps;

  switch (mode) {
    case "datetime":
    case "fulldatetime":
    case "time":
      pickerProps = {
        ...sharedProps,
        ampm: false,
      } as DateTimeAndTimePickerProps;
      break;
    case "date":
    default:
      pickerProps = sharedProps;
      break;
  }

  return (
    <Box
      className={
        styles.container && styles.container?.length > 0
          ? styles.container
          : classes.container
      }
    >
      <DatePickerComponent
        {...pickerProps}
        slotProps={{
          textField: {
            InputProps: {
              classes: {
                root: `${rootStyle} ${classes.rootTimePicker} ${
                  isChangePending ? classes.pendingRootTimePicker : ""
                }`,
              },
              disableUnderline,
            },
            variant: "standard",
          },
        }}
        format={format}
        onError={() => {
          if (onError) {
            onError(true);
          }
        }}
      />
    </Box>
  );
}
