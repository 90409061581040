import ValueIncreaseDatalakeDataType from "./interface/IValueIncreaseDatalakeDataType";
import { IValueIncreaseFrontendData } from "./interface/IValueIncreaseFrontendData";
import { TFunction } from "i18next";
import IBarChartDataElement from "../../shared/components/charts/barStackChartCard/api/IBarChartDataElement";
import { getLabel } from "../../components/charts/machineStateBarChartStacked/api/transformDataFromServer";
import { IBarChartPart } from "../../shared/components/charts/barStackChartCard";
import colors from "../../shared/theme/colors";
import {
  IAggregatedVariableDataFrontendEntry,
  IAggregatedVariableDataResponseEntry,
  IVariableUnitInfo,
} from "../../components/kpiValueDisplayCard/api/IAggregatedVariableDataResponse";
import { getFrontendData } from "../../components/kpiValueDisplayCard/api/useAggregatedVariableQuery";
import { areDatesOnSameDay } from "../../helper/time/areDatesOnSameDay";
import { getTranslatedDisplayName } from "../../shared/components/charts/barStackChartCard/components/chartTooltip";
import TScale from "../time/TScale";
import getScaleForStartEndTimestamps from "../../helper/time/scale/getScaleForStartEndTimestamps";

const AVAILABLE_CHART_COLORS = [
  colors.chart_yellow_base,
  colors.chart_blue_base,
  colors.chart_green_base,
  colors.chart_red_base,
];

const valueIncreaseIds = ["counter_energy"];

function createBarChartData(
  condensedStates: ValueIncreaseDatalakeDataType[],
  unitInfo: IVariableUnitInfo,
  variableListName: string,
  locale: string,
  timezone: string,
  t: TFunction,
): {
  data: IBarChartDataElement[];
  totalValueIncrease: number;
  scale?: TScale;
} {
  let totalValueIncrease = 0;
  let scale: TScale | undefined = undefined;
  const firstEntry = condensedStates[0];
  if (firstEntry) {
    scale = getScaleForStartEndTimestamps(
      new Date(firstEntry.tsStart),
      new Date(firstEntry.tsEnd),
      timezone,
    );
  }

  // Map through the condensedStates and create the IBarChartDataElement array
  const chartData = condensedStates.map(
    (entry: ValueIncreaseDatalakeDataType): IBarChartDataElement => {
      const isPeriodOnSameDay = areDatesOnSameDay(
        entry.tsStart,
        entry.tsEnd,
        timezone,
      );
      const label = getLabel(
        locale,
        t,
        entry.tsStart,
        entry.tsEnd,
        timezone,
        isPeriodOnSameDay,
      );
      const xAxis = getLabel(
        locale,
        t,
        entry.tsStart,
        entry.tsEnd,
        timezone,
        false,
        true,
      );

      // Accumulate the total valueIncrease
      totalValueIncrease += entry.valueIncrease;

      return {
        name: label,
        xAxis: xAxis,
        data: {
          [variableListName]: entry.valueIncrease * unitInfo.conversionFactor,
        },
      };
    },
  );

  // Return the chartData along with the totalValueIncrease
  return {
    data: chartData,
    totalValueIncrease,
    scale,
  };
}

function createBarChartParts(
  dataEntries: IBarChartDataElement[],
  unitInfo: IVariableUnitInfo,
): IBarChartPart[] {
  const partMap = {};
  dataEntries.forEach((entries: IBarChartDataElement) => {
    const dataKeys = Object.keys(entries.data);
    dataKeys.forEach((key: string) => {
      if (!(key in partMap)) {
        const colorIndex = valueIncreaseIds.indexOf(key);
        const dynamicColor = AVAILABLE_CHART_COLORS[colorIndex];
        if (dynamicColor == null) {
          throw new Error(
            `Missing color for value Increase ${key}. Please extend color map!`,
          );
        }
        partMap[colorIndex] = {
          color: dynamicColor,
          name: key,
          unit: unitInfo.displayName,
        };
      }
    });
  });

  return Object.keys(partMap).map((key: string) => partMap[key]);
}

function createValueDisplayData(
  value: number,
  variableListName: string,
  variableUnitInfo: IVariableUnitInfo,
): IAggregatedVariableDataFrontendEntry | undefined {
  if (value === null && value === undefined) {
    return;
  }
  const valueIncreaseData: IAggregatedVariableDataResponseEntry = {
    current: {
      counter_energy: value,
    },
    reference: null,
    unit: variableUnitInfo,
  };

  return getFrontendData(valueIncreaseData, variableListName);
}

export function ValueIncreaseReducer(
  dataFromServer: ValueIncreaseDatalakeDataType[],
  variableUnitInfo: IVariableUnitInfo,
  variableListName: string,
  timezone: string,
  locale: string,
  t: TFunction,
): IValueIncreaseFrontendData {
  const { data, totalValueIncrease, scale } = createBarChartData(
    dataFromServer,
    variableUnitInfo,
    variableListName,
    locale,
    timezone,
    t,
  );
  const avgValueIncrease =
    dataFromServer.length > 0 ? totalValueIncrease / dataFromServer.length : 0;

  const firstEntry: IBarChartDataElement | undefined = data.find(
    (value: IBarChartDataElement) => {
      return value.data != null && Object.keys(value.data).length > 0;
    },
  );
  let parts: IBarChartPart[] = [];

  if (firstEntry) {
    parts = createBarChartParts(data, variableUnitInfo);
  }
  const displayName = getTranslatedDisplayName(variableListName, t);
  return {
    valueIncreaseData: {
      data,
      parts,
      yAxisLabel: `${displayName} [${variableUnitInfo.displayName}]`,
    },
    totalValueIncreaseData: {
      ...(createValueDisplayData(
        totalValueIncrease,
        variableListName,
        variableUnitInfo,
      ) as IAggregatedVariableDataFrontendEntry),
      scale: scale,
    },
    avgValueIncreaseData: {
      ...(createValueDisplayData(
        avgValueIncrease,
        variableListName,
        variableUnitInfo,
      ) as IAggregatedVariableDataFrontendEntry),
      scale: scale,
    },
  };
}
