import {
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { PaginationItem } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import React from "react";
import { TooltipCell } from "../tooltipCell";
import { useTranslation } from "react-i18next";

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { t } = useTranslation();

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => {
        if (
          item.type === "start-ellipsis" ||
          item.type === "end-ellipsis" ||
          item.type === "page"
        ) {
          return null; // Skip rendering page numbers
        }

        let tooltipTitle = "";
        if (item.type === "previous") {
          tooltipTitle = t("shared.georgDataGrid.previousPage");
        } else if (item.type === "next") {
          tooltipTitle = t("shared.georgDataGrid.nextPage");
        }
        const paginationItem = <PaginationItem {...item} />;

        return item.disabled ? (
          paginationItem
        ) : (
          <TooltipCell tooltipText={tooltipTitle}>{paginationItem}</TooltipCell>
        );
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default React.memo(CustomPagination);
