import { Layout } from "react-grid-layout";
import createLayout from "../../../../../shared/components/gridPage/helper/createLayout";

export const BAR_CHART_LAYOUT_KEY = "energy_chart";
export const PIE_TOTAL_LAYOUT_KEY = "energy_total";
export const PIE_AVG_LAYOUT_KEY = "energy_avg";

const layout_LG: Layout[] = [
  { i: BAR_CHART_LAYOUT_KEY, x: 5, y: 0, w: 9, h: 10 },
  { i: PIE_TOTAL_LAYOUT_KEY, x: 0, y: 0, w: 3, h: 5 },
  { i: PIE_AVG_LAYOUT_KEY, x: 0, y: 6, w: 3, h: 5 },
];

const layoutsXS: Layout[] = [
  { i: PIE_TOTAL_LAYOUT_KEY, x: 0, y: 0, w: 6, h: 5 },
  { i: PIE_AVG_LAYOUT_KEY, x: 6, y: 0, w: 6, h: 5 },
  { i: BAR_CHART_LAYOUT_KEY, x: 0, y: 5, w: 12, h: 5 },
];
const layoutsXXS: Layout[] = [
  { i: PIE_TOTAL_LAYOUT_KEY, x: 0, y: 0, w: 12, h: 5 },
  { i: PIE_AVG_LAYOUT_KEY, x: 0, y: 5, w: 12, h: 5 },
  { i: BAR_CHART_LAYOUT_KEY, x: 0, y: 10, w: 12, h: 5 },
];

export type TResizeConfig = {
  [key in TGridLayoutStacklightKEY]: number;
};
type TGridLayoutStacklightKEY = "energy_chart" | "energy_total" | "energy_avg";

// export default KPI_VIEW_LAYOUTS;
export default function getLayoutInfo(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(
    resizeConfig,
    layout_LG,
    layout_LG,
    layoutsXS,
    layoutsXS,
    layoutsXXS,
    layoutsXXS,
  );
}
