import { Box, Paper, Typography } from "@mui/material";
import { IInput } from "../index";
import React from "react";
import useStyles from "./styles";
type StyleObject = {
  inputContainer: string;
  boxContainer?: string;
  textContainer?: string;
  inputRoot?: string;
  textInput?: string;
};

interface ISelectOption {
  label: string | React.ReactElement;
  value: string;
}
interface IProps {
  name: string;
  label: string;
  value?: string;
  onChange: (selected: React.ChangeEvent<HTMLSelectElement>) => void;
  selectOptions: ISelectOption[];
  styles?: StyleObject;
  isDisabled?: boolean;
}

export default function Dropdown({
  name,
  label,
  value,
  onChange,
  selectOptions,
  styles = {
    inputContainer: "",
    boxContainer: "",
    textContainer: "",
    inputRoot: "",
    textInput: "",
  },
  isDisabled = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const rootInputStyles = styles.inputRoot
    ? styles.inputRoot
    : classes.rootHeight;
  return (
    <Box className={styles.boxContainer + classes.container}>
      <Typography
        color="textPrimary"
        variant="body1"
        className={styles.textContainer}
      >
        {label}
      </Typography>
      <Paper className={styles.inputContainer + classes.inputContainer}>
        <IInput
          disableUnderline
          fullWidth={false}
          name={name || ""}
          onChange={onChange}
          select={true}
          selectOptions={selectOptions}
          styles={{
            rootInputStyles: `${classes.root} ${rootInputStyles}`,
            selectIcon: classes.selectIcon,
            textInputStyles: `${styles.textInput} ${classes.textInputSelect}`,
          }}
          value={value}
          disabled={isDisabled}
        />
      </Paper>
    </Box>
  );
}
