/*
 *
 *   BarChartCard (stacked) for Stacklight-View.
 *
 */
import React, { useCallback, useMemo, useState } from "react";
import { Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";
/*Custom Imports*/
import useStyles from "../styles";
import { IList } from "../../index";
import ChartTooltip, { ITooltipProps } from "./components/chartTooltip";
import IBarChartDataElement from "./api/IBarChartDataElement";
import BarStackChartSkeleton from "./components/skeleton";
import ExportButton, {
  IExportEnum,
} from "../../../../components/charts/components/exportButton";
import generateFileName from "../../../../services/generateFileName";
import getIndicatorTranslationByStateName from "../../../../helper/indicator/getIndicatorTranslationByStateName";
import { TIndicatorName } from "../../../../config/states/STATE_COLOR_MAP";
import displayFormatToString from "../../../../helper/time/formatting/displayFormatToString";
import secondsToDisplayFormat from "../../../../helper/time/formatting/secondsToDisplayFormat";
import { ProcessValue } from "../../../../helper/valueRepresentation/customRoundedCurrentReference";

export interface IBarChartPart {
  name: string;
  color: string;
  unit?: string;
}

interface Props {
  chartElements: IBarChartDataElement[];
  label: string;
  barChartParts: IBarChartPart[];
  locale: string;
  timezone: string;
  isLoading?: boolean;
  isEmpty?: boolean;
  hasError?: boolean;
  machineId: string;
  isTooltipEnabled?: boolean;
  showYAxis: boolean;
  YAxisLabel?: string;
}

function roundValue(value: number): string {
  const processedValue = ProcessValue(value);
  if (processedValue === null) {
    return value.toString();
  }
  return processedValue.value + processedValue.unit;
}

export default function BarChartCard({
  label,
  chartElements,
  barChartParts,
  locale,
  timezone,
  isLoading = false,
  isEmpty = true,
  hasError = false,
  isTooltipEnabled = true,
  machineId,
  showYAxis,
  YAxisLabel,
}: Props): React.ReactElement {
  const [triggerExport, setTriggerExport] = useState<IExportEnum>(
    IExportEnum.NONE,
  );
  const { t } = useTranslation();
  const [containerWidth, setContainerWidth] = useState(0);
  const data = useMemo(
    () =>
      chartElements.map((value: IBarChartDataElement) => {
        return { name: value.name, xAxis: value.xAxis, ...value.data };
      }),
    [chartElements],
  );
  const getFontSize = useCallback(() => {
    if (containerWidth < 350) return 12;
    return 14;
  }, [containerWidth]);
  const { classes } = useStyles();
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  // useCurrentPng usage (isLoading is optional)
  const [getChartPng, { ref: chartRef }] = useCurrentPng();
  // TODO: use a suitable ExportPNG Library instead of recharts-to-png because it is not working on multi-chart component
  const handleExportPNG = useCallback(async () => {
    const png = await getChartPng();

    // Verify that png is not undefined
    if (png) {
      // Download with FileSaver
      const fileName = generateFileName(
        machineId,
        locale,
        t,
        timezone,
        "Chart",
        IExportEnum.PNG,
      );
      FileSaver.saveAs(png, fileName);
    }
    handleExportHandled();
  }, [getChartPng]);

  // get csv data from chart data
  const converDataToCSV = useCallback(() => {
    if (!data || data.length === 0) return "";

    // Extract unique indicator IDs.
    const uniqueIndicatorIds = Array.from(
      new Set(chartElements.flatMap((element) => Object.keys(element.data))),
    );

    // Map IDs to names.
    const indicatorMap = uniqueIndicatorIds.reduce((acc, name) => {
      const translatedName = getIndicatorTranslationByStateName(
        name as TIndicatorName,
        t,
      ).name;

      acc.set(name, translatedName);
      return acc;
    }, new Map<string, string>());
    const indicatorValues = Array.from(indicatorMap.values());
    // Create CSV header.
    const headers = [
      t("dashboard.commonChart.dateTime"),
      ...indicatorValues,
    ].join("; ");

    // Extract and format data rows.
    const csvRows = chartElements.map((entry) => {
      return [
        entry.name,
        ...uniqueIndicatorIds.map((key) => {
          let retString = "";
          if (showYAxis) {
            retString = entry.data[key].toString();
          } else {
            const displayFormat = secondsToDisplayFormat(
              entry.data[key]?.toString() || "0",
            );
            retString = displayFormatToString(t, displayFormat).replace(
              "\n",
              "",
            );
          }
          return retString || 0;
        }),
      ].join("; ");
    });
    return [headers, ...csvRows].join("\n");
  }, [chartElements]);
  const handleExportCSV = useCallback(async () => {
    const convertedCSV = converDataToCSV();
    if (!convertedCSV) {
      handleExportHandled();
      return;
    }
    const blob = new Blob([convertedCSV], { type: "text/csv;charset=utf-8" });
    const fileName = generateFileName(
      machineId,
      locale,
      t,
      timezone,
      "Chart",
      IExportEnum.CSV,
    );
    FileSaver.saveAs(blob, fileName);
    handleExportHandled();
  }, [data]);

  const handleExport = (type: IExportEnum) => {
    switch (type) {
      case "csv":
        handleExportCSV();
        break;
      case "png":
        handleExportPNG();
        break;
      default:
        setTriggerExport(IExportEnum.NONE);
    }
  };
  const handleExportHandled = () => {
    setTriggerExport(IExportEnum.NONE);
  };
  const leftMargin = useMemo(() => (showYAxis ? 30 : 10), [showYAxis]);

  return (
    <Paper className={classes.container}>
      <IList
        label={label}
        labelColor="textPrimary"
        labelVariant="body1"
        styles={{
          GridActionItems: classes.gridActionItems,
        }}
        actionComponent={
          <ExportButton
            disabled={
              isLoading ||
              triggerExport !== IExportEnum.NONE ||
              isEmpty ||
              hasError
            }
            onClick={handleExport}
          />
        }
      />
      <div className={classes.chartContainer}>
        <ResponsiveContainer
          className={classes.chartArea}
          width={isXs ? "100%" : "100%"}
          height={"100%"}
          onResize={(width) => setContainerWidth(width)}
        >
          {isLoading ? (
            <BarStackChartSkeleton showYAxis={showYAxis} />
          ) : (
            <BarChart data={data} ref={chartRef} margin={{ left: leftMargin }}>
              <XAxis
                axisLine={true}
                dataKey="xAxis"
                tick={{
                  fontSize: getFontSize(),
                }}
                interval="preserveStartEnd"
                minTickGap={10}
              />
              <YAxis
                label={{
                  value: YAxisLabel,
                  angle: -90,
                  position: "insideLeft",
                  dx: -20,
                  style: { textAnchor: "middle", fontSize: getFontSize() },
                }}
                style={{
                  fontSize: getFontSize(),
                }}
                tickFormatter={(value) => roundValue(value)}
                hide={!showYAxis}
              />
              <Tooltip
                content={(props) =>
                  isTooltipEnabled && (
                    <ChartTooltip
                      tooltipData={props as ITooltipProps}
                      isDateTime={!showYAxis}
                    />
                  )
                }
              />
              {barChartParts.map(({ color, name, unit }: IBarChartPart) => (
                <Bar
                  key={name}
                  dataKey={name}
                  unit={unit}
                  stackId="a"
                  fill={color}
                />
              ))}
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
    </Paper>
  );
}
