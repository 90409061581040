import { ITimeSlot } from "../../../../redux/reducers/timeSelectReducer";
import createCondensedStateListQuery from "./createCondensedStateListQuery";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

export interface IStackEntryData {
  indicatorIdentificationTypeId: number;
  value: number;
}

export interface ICondensedState {
  data: IStackEntryData[];
  tsEnd: string;
  tsStart: string;
}

interface ICondensedStateList {
  getCondensedStateList: ICondensedState[];
}

interface ICondensedStateListResponse {
  condensedStatelist: ICondensedState[] | undefined;
  isLoading: boolean;
  hasError: boolean;
  isEmpty: boolean;
}

export default function useCondensedStateListQuery(
  allIndicatorIds: string[],
  timeSlot: ITimeSlot,
  timezone: string,
  machineTypeId: string,
): ICondensedStateListResponse {
  const [barChartDataStacked, setBarChartDataStacked] = useState<
    ICondensedState[] | undefined
  >([]);
  const [hasError, setHasError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const { query, parameters } = createCondensedStateListQuery(
    allIndicatorIds,
    machineTypeId,
    timeSlot,
    timezone,
  );
  const [doQuery, { data: dataFromServer, loading: isLoading, error }] =
    useLazyQuery<ICondensedStateList>(query, parameters);

  useEffect(() => {
    if (machineTypeId !== "" && allIndicatorIds.length > 0) {
      doQuery();
    }
  }, [machineTypeId, allIndicatorIds.length, doQuery, timeSlot]);

  useEffect(() => {
    setHasError(error != null);
    setBarChartDataStacked(dataFromServer?.getCondensedStateList);
  }, [dataFromServer?.getCondensedStateList, error]);
  useEffect(() => {
    const isDataArrEmpty = barChartDataStacked?.every(
      (entry: ICondensedState) => {
        return entry.data.length === 0;
      },
    );
    setIsEmpty(
      error == null &&
        (barChartDataStacked == null ||
          isDataArrEmpty ||
          barChartDataStacked?.length <= 0),
    );
  }, [barChartDataStacked, error]);

  return {
    condensedStatelist: barChartDataStacked,
    isLoading,
    hasError,
    isEmpty,
  };
}
